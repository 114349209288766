import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import { getUserList, getCompany, ceoSummaryFNC, ceoSummaryHeaderFilterFNC } from '../Api'
import Header from '../Component/Header';
import { Link } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import moment from 'moment-timezone';
// import { Alert } from 'bootstrap';

var timer

class SummaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      userList: [],
      allList: [],
      companyList: [],
      departmentList: [],
      SelectedYear: moment().format('YYYY'),
      SelectedMonth: moment().format('MM'),
      DisplayYears: [],
      background: '#0D6EFD',
      SelectedWholeYear: false,
      CompanyListsss: {},
      FilterCompany: '',
      FilterBranch: '',
      FilterCategory: '',
      Companyfilter: [],
      Branchfilter: [],
      Categoryfilter: [],
      hiddenPahang: true,
    }
  }

  async componentDidMount() {
    if(this.props.token === '') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    }
    // const allList = await getCompany(this.props.username, this.props.token, '', '')
    // let companyList = allList.map((item) => item.Company)
    // companyList = [...new Set(companyList)]
    // this.setState({ allList, companyList }, () => this.getUserListF())
    this.DisplayYears();
    this.GetSummaryData();
  }

  async CallHeader() {
    const SelectedMonth = this.state.SelectedMonth
    const SelectedYear = this.state.SelectedYear
    const SelectedFullYear = this.state.SelectedWholeYear
    const getheader = await ceoSummaryHeaderFilterFNC(SelectedYear, SelectedMonth, SelectedFullYear, this.props.username, this.props.token);
    if (getheader) {
      this.setState({ 
        Companyfilter: getheader.companyFilter,
        Branchfilter: getheader.branchFilter,
        Categoryfilter: getheader.categoryFilter,
      })
    }
  }

  async GetSummaryData() {
    const SelectedMonth = this.state.SelectedMonth
    const SelectedYear = this.state.SelectedYear
    const SelectedFullYear = this.state.SelectedWholeYear
    let FilterList = {
      company: this.state.FilterCompany,
      branch: this.state.FilterBranch,
      category: this.state.FilterCategory,
    } 
    const getData = await ceoSummaryFNC(SelectedYear, SelectedMonth, SelectedFullYear, FilterList, this.props.username, this.props.token);
    if (getData) {
      this.CallHeader();
      this.setState({ CompanyListsss: getData })
    }
  }

  DisplayYears() {
    const start = moment().format('YYYY') - 10
    const end = moment().format('YYYY')
    let DisplayYears = this.state.DisplayYears
    for (var i = start + 1; i < end; i++) {
      DisplayYears.push(i.toString())
    }
    DisplayYears.push(end);
    this.setState({ DisplayYears })
  }

  //   async getUserListF() {
  //     let userList = await getUserList(this.props.username, this.props.token, 'details')
  //     userList = userList.map((item) => {
  //       const departmentJSON = JSON.parse(item.Department)
  //       const DisplayDepartment = this.state.allList.filter((alllistitem) => departmentJSON.includes(alllistitem.ID))
  //       return {
  //         ...item,
  //         Department: departmentJSON,
  //         DisplayDepartment,
  //       }
  //     })
  //     this.setState({ userList })
  //   }

  FilterTableView(e, Selecttype) {
    if(Selecttype === 'FilterCompany') {
      this.setState({ FilterCompany: e }, () => { this.GetSummaryData(); })
    }
    if(Selecttype === 'FilterBranch') {
      this.setState({ FilterBranch: e }, () => { this.GetSummaryData(); })
    }
    if(Selecttype === 'FilterCategory') {
      this.setState({ FilterCategory: e }, () => { this.GetSummaryData(); })
    }
  }

  FilterCancel() {
    this.setState({
      FilterCompany: '',
      FilterBranch: '',
      FilterCategory: '',
    }, () => {
      this.GetSummaryData()
    })
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  render() {
    return (
      <div>
        <Header/>
        <Container fluid style={{ fontSize: '13px' }}>
          <Alert className='AlertPage' style={{ zIndex: 999999, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].SummaryList}</h2>
              <div style={{ marginRight: 10, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Month}</div>
              <Form.Select style={{ width: 100}} onChange={(e) => this.setState({ SelectedMonth: e.target.value }, () => { this.GetSummaryData(); })} value={this.state.SelectedMonth}>
                {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((item, index) => 
                  <option key={index} value={item}>{language[this.props.currentLanguage][item]}</option>
                )}
              </Form.Select>
              {this.state.DisplayYears.length > 0 &&
            <>
              <div style={{ marginRight: 10, fontWeight: 'bold', marginLeft: 5 }}>{language[this.props.currentLanguage].Year}</div>
              <Form.Select style={{ width: 100}} onChange={(e) => this.setState({ SelectedYear: e.target.value },() => { this.GetSummaryData(); })} value={this.state.SelectedYear}>
                {this.state.DisplayYears.map((item, index) => 
                  <option key={index} value={item}>{item}</option>
                )}
              </Form.Select>
            </>}
              <div className="dcc button"
                style={{ backgroundColor: this.state.background, marginLeft: 10,
                  fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, paddingLeft: 12, paddingRight: 12, marginRight: 5 }} 
                onMouseEnter={() => {
                  this.setState({ background: '#00B79B' });
                }}
                onMouseLeave={() => {
                  this.setState({ background: '#0D6EFD' });
                }}
                variant="success" onClick={() => this.FilterCancel()}>
                <span>
                  {language[this.props.currentLanguage].Search}
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 10, fontWeight: 'bold'}}>{language[this.props.currentLanguage].WholeYear}</div>
                <Form.Check
                  type="checkbox"
                  checked={this.state.SelectedWholeYear}
                  onChange={(e) => this.setState({ SelectedWholeYear: e.target.checked }, () => { this.GetSummaryData()})}
                /></div>
            </div>
          </div>
          {/* <Table striped bordered hover responsive style={{ width: '90%'}}>
          <thead>
            <tr>
              <th>{language[this.props.currentLanguage].Company}</th>
              <th>{language[this.props.currentLanguage].Branch}</th>
              <th>{language[this.props.currentLanguage].Subject}</th>
              <th>{'Total Claims'}</th>
              <th>{'Approve Claims'}</th>
              <th>{'Pending Claims'}</th>
              <th>{'Paid Claims'}</th>
              <th>{'Unpaid Claims'}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{'halo'}</td>
              <td>{'halo'}</td>
              <td>{'halo'}</td>
              <td>{'000.00'}</td>
              <td>{'000.00'}</td>
              <td>{'000.00'}</td>
              <td>{'000.00'}</td>
              <td>{'000.00'}</td>
            </tr>
          </tbody>
          {this.state.userList.length > 0 ?
            <tbody>
              <tr>
                <td>{'halo'}</td>
                <td>{'halo'}</td>
                <td>{'halo'}</td>
                <td>{'000.00'}</td>
                <td>{'000.00'}</td>
                <td>{'000.00'}</td>
                <td>{'000.00'}</td>
                <td>{'000.00'}</td>
              </tr>
            </tbody>
            :
            <tbody>
              <tr style={{ backgroundColor: 'lightgray'}}>
                <td colSpan="8" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
              </tr>
            </tbody>}
        </Table> */}
          <div className="dcc">
            <div className="dcc" style={{ width: '85%', marginBottom: 50 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", border: "1px solid black", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: 'darkblue' }}>
                          <div  className="resultbottomforSummary" style={{width:'calc(100%)', color: 'white', padding: 5 }}>
                            <select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.FilterCompany} onChange={(e) => this.FilterTableView(e.target.value, 'FilterCompany')}>
                              <option value=''>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Company}`}</option>
                              {this.state.Companyfilter.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select>
                          </div>
                          <div className="resultbottomforSummary" style={{width:'calc(100%)', color: 'white', padding: 5}}>
                            <select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.FilterBranch} onChange={(e) => this.FilterTableView(e.target.value, 'FilterBranch')}>
                              <option value=''>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Branch}`}</option>
                              {this.state.Branchfilter.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select>
                          </div>
                          <div className="resultbottomforSummary" style={{width:'calc(100%)', color: 'white', padding: 5}}>
                            <select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.FilterCategory} onChange={(e) => this.FilterTableView(e.target.value, 'FilterCategory')}>
                              <option value=''>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Subject}`}</option>
                              {this.state.Categoryfilter.map((item, index) => 
                                <option key={index} value={item}>{item}</option>
                              )}
                            </select>
                          </div>
                          <div className="resultbottomforSummary" style={{width:'calc(100%)', color: 'white', padding: 5}}>
                            {'Total Claims'}
                          </div>
                          <div className="resultbottomforSummary" style={{width:'calc(100%)', color: 'white', padding: 5}}>
                            {'Approve Claims'}
                          </div>
                          <div className="resultbottomforSummary" style={{width:'calc(100%)', color: 'white', padding: 5}}>
                            {'Pending Claims'}
                          </div>
                          <div className="resultbottomforSummary" style={{width:'calc(100%)', color: 'white', padding: 5}}>
                            {'Paid Claims'}
                          </div>
                          <div className="resultbottomforSummary" style={{width:'calc(100%)', color: 'white', padding: 5}}>
                            {'Unpaid Claims'}
                          </div>
                        </div>
                      </div>
                      {JSON.stringify(this.state.CompanyListsss) !== '{}' &&
                  <>
                    {Object.keys(this.state.CompanyListsss).filter(item => item !== 'grandTotal').map((items, index) => {
                      return(
                        <div key={index} style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10 }}>
                          <div style={{width:'100%' , position: "relative", flexDirection: 'row', display: 'flex'}}>
                            <div className="resultbottomforSummary" style={{width:'calc(100%)'}}>
                              {items}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {Object.keys(this.state.CompanyListsss[items]).sort().map((getBranch) => {
                                return(
                                  <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                                    {Object.keys(this.state.CompanyListsss[items][getBranch]).map((getcate, idn) => {
                                      if (this.state.hiddenPahang && getBranch.startsWith('PAHANG - ')) {
                                        return null
                                      }
                                      return(
                                        <div key={idn} className="resultbottomforSummary" style={{width:'calc(100%)', color: (idn !== 0) ? 'transparent' : 'black', display: 'flex'}}>
                                          {(idn !== 0) ? `${'-'}` : `${getBranch}`}{getBranch === 'PAHANG' && <div style={{ marginLeft: 10, cursor: 'pointer' }}onClick={() => this.setState({ hiddenPahang: !this.state.hiddenPahang })}>⬇️</div>}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {Object.keys(this.state.CompanyListsss[items]).sort().map((getBranch) => {
                                return(
                                  <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                                    {Object.keys(this.state.CompanyListsss[items][getBranch]).map((getcate, idn) => {
                                      if (this.state.hiddenPahang && getBranch.startsWith('PAHANG - ')) {
                                        return null
                                      }
                                      return(
                                        <div key={idn} className="resultbottomforSummary" style={{width:'calc(100%)'}}>
                                          {getcate}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {Object.keys(this.state.CompanyListsss[items]).sort().map((getBranch) => {
                                return(
                                  <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                                    {Object.keys(this.state.CompanyListsss[items][getBranch]).map((getcate, idn) => {
                                      if (this.state.hiddenPahang && getBranch.startsWith('PAHANG - ')) {
                                        return null
                                      }
                                      return(
                                        <div key={idn} className="resultbottomforSummary" style={{width:'calc(100%)', cursor: 'pointer', color: 'blue' }}
                                          onClick={() => window.location.href=`/Report?Company=${items}&Branch=${getBranch}&Category=${getcate}&Year=${this.state.SelectedYear}&Month=${this.state.SelectedMonth}&WholeYear=${this.state.SelectedWholeYear}` }>
                                          {Number(this.state.CompanyListsss[items][getBranch][getcate].TotalClaim).toLocaleString()}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {Object.keys(this.state.CompanyListsss[items]).sort().map((getBranch) => {
                                return(
                                  <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                                    {Object.keys(this.state.CompanyListsss[items][getBranch]).map((getcate, idn) => {
                                      if (this.state.hiddenPahang && getBranch.startsWith('PAHANG - ')) {
                                        return null
                                      }
                                      return(
                                        <div key={idn} className="resultbottomforSummary" style={{width:'calc(100%)', cursor: 'pointer', color: 'blue' }} 
                                          onClick={() => window.location.href=`/CashOut?Company=${items}&Branch=${getBranch}&Category=${getcate}&Year=${this.state.SelectedYear}&Month=${this.state.SelectedMonth}&WholeYear=${this.state.SelectedWholeYear}&LatestStatus=${'Approve'}` }>
                                          {Number(this.state.CompanyListsss[items][getBranch][getcate].ApprovedClaim).toLocaleString()}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {Object.keys(this.state.CompanyListsss[items]).sort().map((getBranch) => {
                                return(
                                  <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                                    {Object.keys(this.state.CompanyListsss[items][getBranch]).map((getcate, idn) => {
                                      if (this.state.hiddenPahang && getBranch.startsWith('PAHANG - ')) {
                                        return null
                                      }
                                      return(
                                        <div key={idn} className="resultbottomforSummary" style={{width:'calc(100%)', cursor: 'pointer', color: 'blue'}}
                                          onClick={() => window.location.href=`/Approvel?Company=${items}&Branch=${getBranch}&Category=${getcate}&Year=${this.state.SelectedYear}&Month=${this.state.SelectedMonth}&WholeYear=${this.state.SelectedWholeYear}&LatestStatus=${''}` }>
                                          {Number(this.state.CompanyListsss[items][getBranch][getcate].PendingClaim).toLocaleString()}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {Object.keys(this.state.CompanyListsss[items]).sort().map((getBranch) => {
                                return(
                                  <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                                    {Object.keys(this.state.CompanyListsss[items][getBranch]).map((getcate, idn) => {
                                      if (this.state.hiddenPahang && getBranch.startsWith('PAHANG - ')) {
                                        return null
                                      }
                                      return(
                                        <div key={idn} className="resultbottomforSummary" style={{width:'calc(100%)', cursor: 'pointer', color: 'blue'}}
                                          onClick={() => window.location.href=`/CashOut?Company=${items}&Branch=${getBranch}&Category=${getcate}&Year=${this.state.SelectedYear}&Month=${this.state.SelectedMonth}&WholeYear=${this.state.SelectedWholeYear}&LatestStatus=${'CashOut'}` }>
                                          {Number(this.state.CompanyListsss[items][getBranch][getcate].PaidClaim).toLocaleString()}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {Object.keys(this.state.CompanyListsss[items]).sort().map((getBranch) => {
                                return(
                                  <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                                    {Object.keys(this.state.CompanyListsss[items][getBranch]).map((getcate, idn) => {
                                      if (this.state.hiddenPahang && getBranch.startsWith('PAHANG - ')) {
                                        return null
                                      }
                                      return(
                                        <div key={idn} className="resultbottomforSummary" style={{width:'calc(100%)', cursor: 'pointer', color: 'blue'}}
                                          onClick={() => window.location.href=`/CashOut?Company=${items}&Branch=${getBranch}&Category=${getcate}&Year=${this.state.SelectedYear}&Month=${this.state.SelectedMonth}&WholeYear=${this.state.SelectedWholeYear}&LatestStatus=${'Approve'}` }>
                                          {Number(this.state.CompanyListsss[items][getBranch][getcate].UnpaidClaim).toLocaleString()}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div style={{width:'100%', position: "relative", flexDirection: 'row', display: 'flex', fontWeight: 'bold'}}>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {''}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                              {''}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                              {'Total'}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                              {Number(this.state.CompanyListsss[items].totalClaim).toLocaleString()}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                              {Number(this.state.CompanyListsss[items].totalApprovedClaim).toLocaleString()}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                              {Number(this.state.CompanyListsss[items].totalPendingClaim).toLocaleString()}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                              {Number(this.state.CompanyListsss[items].totalPaidClaim).toLocaleString()}
                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                              {Number(this.state.CompanyListsss[items].totalUnPaidClaim).toLocaleString()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div style={{width:'100%', position: "relative", flexDirection: 'row', display: 'flex', fontWeight: 'bold', borderTop: '1px solid #d8d8d8'}}>
                      <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                        {''}
                      </div>
                      <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)' }}>
                        {''}
                      </div>
                      <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                        {'GrantTotal'}
                      </div>
                      <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                        {Number(this.state.CompanyListsss.grandTotal.grandTotalCLaim).toLocaleString()}
                      </div>
                      <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                        {Number(this.state.CompanyListsss.grandTotal.grandTotalApproved).toLocaleString()}
                      </div>
                      <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                        {Number(this.state.CompanyListsss.grandTotal.grandTotalPending).toLocaleString()}
                      </div>
                      <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                        {Number(this.state.CompanyListsss.grandTotal.grandTotalPaid).toLocaleString()}
                      </div>
                      <div style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'black' }}>
                        {Number(this.state.CompanyListsss.grandTotal.grandTotalUnPaid).toLocaleString()}
                      </div>
                    </div>
                  </>
                      }
                      {JSON.stringify(this.state.CompanyListsss) === '{}' && 
                  <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                    <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: 'lightgray'}}>
                      <div  className="resultbottomNodata" style={{width:'calc(100%)'}}>
                        {language[this.props.currentLanguage].NoData}
                      </div>
                    </div>
                  </div>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (username, role, token) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: username,
          role: role,
          token: token,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SummaryList);
  