import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { Container, Form, Button, Modal, Alert, Table } from 'react-bootstrap';
import { getBase64 } from '../Component/getBase64';
import { language } from '../language';
// import PDF1 from '../image/PDF2.png';
import Select from "react-select";
import Header from '../Component/Header';
import DisplayTable from '../Component/DisplayTable';
import { exportReportExcel } from '../jsonToXlsx';
import loading from '../image/loading.gif';
import { getCompany, getCategory, PostClaim, EditClaim, DeleteClaim, reClaim, getClaimHistoryRecordFNC, getUser, headerFilterFNC, cashOutClaimListFNC, exportClaimListFNC, returnCategoryFNC } from '../Api'
import moment from 'moment-timezone';

var timer

class Claim extends React.Component {

  fileObj = [];
  fileArray = [];
  newfileArray = [];
  Base64 = []
  constructor(props) {
    super(props);
    this.state = {
      pagetype: 'HomePage',
      OpenViewReceiptModal: false,
      ClaimViewReceipt: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      userList: [],
      ClaimSelectedData: [],
      GetAllCompany: [],
      SelectedUSername: this.props.username,
      AfterFilterCompany: [],
      CompanyArray: [],
      BranchArray: [],
      CategoriesArray: [],
      sub1Array: [],
      sub2Array: [],
      sub3Array: [],
      sub4Array: [],
      sub5Array: [],
      sub6Array: [],
      DepartmentArray: [],
      userObj: [],
      filterUser: '',
      fromDate: moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      claimData: [],
      AllClaimData: [],
      UploadModal: false,
      selectedDate: moment().format('YYYY-MM-DD'),
      Base64file: '',
      Company: '',
      Branch: '',
      Department: '',
      Category: '',
      sub1: '',
      sub2: '',
      sub3: '',
      sub4: '',
      sub5: '',
      sub6: '',
      ClaimTitle: '',
      Remark: '',
      AmountClaim: '',
      FilterCompany: [],
      FilterSubSub: [],
      FilterBranch: [],
      FilterDepartment: [],
      FilterStatus: [],
      FilterName: [],
      FilterSub: [],
      FilterApprovalStatus: [],
      FilterApproveAmount: [],
      FilterAmount: [],
      FilterApprove: [],
      FilterAccounting: [],
      ClaimType: '',
      SelectFilterCompany: 'All',
      SelectFilterBranch: 'All',
      SelectFilterDepartment: 'All',
      ShowFilterName: 'All',
      ShowFilterSub: 'All',
      ShowFilterSubSub: 'All',
      ShowFilterApprovalStatus: 'All',
      ShowFilterApproveAmount: 'All',
      ShowFilterAmount: 'All',
      ShowFilterApprover: 'All',
      ShowFilterStatus: 'All',
      ShowFilterAccounting: 'All',
      EditID: '',
      fastActions: false,
      reClaimfastActions: false,
      SelectedActions: [],
      SelectedReClaim: [],
      uploadloading: false,
      fasttype: '',
      SelectedStatus: '',
      SelectedUser: '',
      optionsUsername: '',
      TotalAmount: {TotalAmount: 0, TotalApproveAmount: 0},
      background: '#0D6EFD',
      ReturnMessage: '',
      DisplayHistory: false,
      HistoryData: [],
      NewFilterArray: [],
      OpenFilter: true,
      CompanyList: [],
      BranchList: [],
      DepartmenList: [],
      CompanyBranch: [],
      CategoryList: [],
      Sub1List: [],
      Sub2List: [],
      Sub3List: [],
      Sub4List: [],
      Sub5List: [],
      Sub6List: [],
      UserList: [],
      LatestUpdateList: [
        'Pending',
        'Approve',
        'CashOut',
        'Reject',
      ],
      FirstApprove: '',
      FirstApproveStatus: '',
      Accountant: '',
      SelectCompany: '',
      SelectBranch: [],
      SelectDepartment: [],
      SelectedSub1: '',
      SelectedSub2: '',
      SelectedSub3: '',
      SelectedSub4: '',
      SelectedSub5: '',
      SelectedSub6: '',
      SelectName: 'All',
      SelectCategory: '',
      SelectLatestUpdate: '',
      KeyinAmount: '',
      ApprovedAmount: '',
      page: 1,
      countperpage: 20,
      countpagemax: 1,
      countpagemin: 1,
      TotalPages: 1,
      RunTotalPage: [],
      BorderHide: true,
      SelectedAll: false,
      AllSelectedIDs: [],
      SelectedOptionBranch: [],
      SelectedBranchs: [],
      SelectedDeparts: [],
      SelectedOptionDepart: [],
      SelectedReclaimData: [],
      SelectedReClaimDetails: [],
    }
    this.handleClose = this.handleClose.bind(this);
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    // this.CloseViewReceipt = this.CloseViewReceipt.bind(this);
    // this.WatchReceipt = this.WatchReceipt.bind(this);
    this.OpenClaimModal = this.OpenClaimModal.bind(this);
    this.SelectedOption = this.SelectedOption.bind(this);
    this.DeleteClaims = this.DeleteClaims.bind(this);
    this.FilterTableView = this.FilterTableView.bind(this);
    this.SelectedActions = this.SelectedActions.bind(this);
    this.SelectedReClaim = this.SelectedReClaim.bind(this);
    this.SetStatePage = this.SetStatePage.bind(this);
    this.RunPageViews = this.RunPageViews.bind(this);
    this.SetStateProps = this.SetStateProps.bind(this);
    this.handleXLSX = this.handleXLSX.bind(this);
    this.SelectAll = this.SelectAll.bind(this);
    this.SelectedReAllID = this.SelectedReAllID.bind(this);
  }

  async componentDidMount() {
    if(this.props.token === '') {
      localStorage.removeItem('claimtoken');
      localStorage.removeItem('claimusername');
      localStorage.removeItem('claimrole');
      localStorage.removeItem('CompanyData');
      window.location.href = '/';
    }
    if (this.props.username === '') {
      this.props.history.push('/')
    }
    if (this.props.role === 'User' || this.props.role === 'Account' || this.props.role === 'CEO') {
      this.setState({filterUser: this.props.username }, ()=> {
        this.UserCallApi();
        this.getCategories();
      })
    }
    let FilterCompany = '';
    let getUserList = '';
    if (this.props.role !== 'User') {
      getUserList = await getUser(
        this.props.username, 
        this.props.token,
      );
    } else {
      getUserList = [];
    }
    const getCompanyList = JSON.parse(this.props.CompanyData)
    this.FilteredBranch(JSON.parse(this.props.CompanyData));
    FilterCompany = getCompanyList.map((item) => item.Company)
    FilterCompany = [...new Set(FilterCompany)];
    const CheckRoleBoolean = this.props.role === 'CEO' ? true : false
    this.setState({ 
      CompanyList: FilterCompany, 
      CompanyBranch: getCompanyList,
      UserList: getUserList,
      BorderHide: CheckRoleBoolean,
      // TableLoading: false
    });
    // if (this.props.role === 'Account' || this.props.role === 'CEO') {
    //   userList.forEach((item) => {
    //     tempObject = {
    //       ...tempObject,
    //       value: item.Username,
    //       label: item.Name,
    //     }
    //     tempArray.push(tempObject)
    //   })
    //   tempArray.unshift({value: 'All', label: 'All'}, {value: this.props.username, label: this.props.username})
    //   this.setState({filterUser: tempArray[0].value, SelectedUser: tempArray[0], optionsUsername: tempArray }, ()=> {
    //     this.UserCallApi();
    //   })
    // }
  }

  async getCategories() {
    const getCate = await returnCategoryFNC(this.state.SelectName, this.props.username, this.props.token, this.state.SelectDepartment)
    this.setState({ 
      FilterSub: getCate,
    })
  }

  SetStateProps(value){
    this.setState({[value]: !this.state[value]})
  }

  
  RunTotalPage() {
    const TotalPages = this.state.TotalPages
    let RunTotalPage = this.state.RunTotalPage
    if (this.state.page === 1 && this.state.RunTotalPage.length === 0) {
      if(TotalPages > 5) {
        for(var i=0; i < 5; i++) {
          RunTotalPage.push(i+1)
        }
        this.setState({ RunTotalPage })
      } else {
        for(var z=0; z < TotalPages; z++) {
          RunTotalPage.push(z+1)
        }
        this.setState({ RunTotalPage })
      }
    }
  }

  changePage() {
    let RunTotalPage = this.state.RunTotalPage
    const TotalPages = this.state.TotalPages
    let Front = RunTotalPage[0]
    let Back = RunTotalPage[RunTotalPage.length - 1]
    if (TotalPages > 5 && this.state.page === Back && this.state.page !== Number(TotalPages)) {
      if (Number(TotalPages) - Back >= 2) {
        const AfterFilter = RunTotalPage.filter(item => item !== Front && item !== RunTotalPage[1]);
        AfterFilter.push(Back + 1)
        AfterFilter.push(Back + 2)
        this.setState({ RunTotalPage: AfterFilter })
      } else if (Number(TotalPages) - Back === 1) {
        const AfterFilter = RunTotalPage.filter(item => item !== Front);
        AfterFilter.push(Back + 1)
        this.setState({ RunTotalPage: AfterFilter })
      }
    }
    if (TotalPages > 5 && this.state.page === Front && this.state.page !== 1) {
      if (Front - 1 >= 2) {
        const AfterFilter = RunTotalPage.filter(item => item !== Back && item !== RunTotalPage[RunTotalPage.length - 2]);
        AfterFilter.unshift(Front - 1)
        AfterFilter.unshift(Front - 2)
        this.setState({ RunTotalPage: AfterFilter })
      } else if (Front - 1 === 1) {
        const AfterFilter = RunTotalPage
        AfterFilter.unshift(Front - 1)
        this.setState({ RunTotalPage: AfterFilter })
      }
    }
    if (this.state.page === 1 && RunTotalPage.length === 5) {
      this.setState({
        RunTotalPage: [],
      })
    }
    if (this.state.page === TotalPages && RunTotalPage.length === 5) {
      this.setState({ RunTotalPage: [] })
      let AddArray = []
      AddArray.push(Number(TotalPages) - 4)
      AddArray.push(Number(TotalPages) - 3)
      AddArray.push(Number(TotalPages) - 2)
      AddArray.push(Number(TotalPages) - 1)
      AddArray.push(Number(TotalPages))
      this.setState({ RunTotalPage: AddArray })
    }
    this.setState({ claimData: [] }, () => {
      this.UserCallApi();
    });
  }

  async CallHeader() {
    const getheader = await headerFilterFNC(this.state.fromDate, this.state.toDate, 'Claim', this.props.username, this.props.token);
    if (getheader) {
      let ArrayDepart = []
      getheader.FilterDepartment.forEach((items) => {
        ArrayDepart.push({value: items, label: items})
      })
      this.setState({ 
        FilterAccounting: getheader.FilterAccounting,
        FilterAmount: getheader.FilterAmount,
        FilterApprovalStatus: getheader.FilterApprovalStatus,
        FilterApprove: getheader.FilterApprove,
        SelectedOptionDepart: ArrayDepart,
        FilterApproveAmount: getheader.FilterApproveAmount,
        FilterBranch: getheader.FilterBranch,
        FilterCompany: getheader.FilterCompany,
        FilterDepartment: getheader.FilterDepartment,
        FilterName: getheader.FilterName,
        FilterStatus: getheader.FilterStatus,
        // FilterSub: getheader.FilterSub,
        FilterSubSub: getheader.FilterSubSub,
      })
    }
  }

  FilteredBranch(CompanyData) {
    let Branchs = []
    let FilterBranch = '';
    FilterBranch = CompanyData.map((item) => item.Branch)
    FilterBranch = [...new Set(FilterBranch)];
    FilterBranch.forEach((item) => {
      Branchs.push({value: item, label: item})
    })
    this.setState({ BranchList: FilterBranch, SelectedOptionBranch: Branchs })
  }

  async getFilterBranch(selectedCompany) {
    let FilterBranch = [];
    if (selectedCompany !== '') {
      await this.setState({ BranchList: []});
      FilterBranch = this.state.CompanyBranch.filter((item) => item.Company === selectedCompany)
      FilterBranch = FilterBranch.map((item) => item.Branch)
      FilterBranch = [...new Set(FilterBranch)];
      this.setState({ 
        BranchList: FilterBranch, 
      });
    } else {
      this.setState({ 
        BranchList: [], 
        DepartmenList: [],
        CategoryList: [],
        LatestUpdateList: [],
      });
    }
  }

  async getFilterDepartment(selectedBranch) {
    let FilterDepartment = [];
    if (selectedBranch !== '') {
      await this.setState({ DepartmenList: []});
      FilterDepartment = this.state.CompanyBranch.filter((item) => (item.Branch === selectedBranch && item.Company === this.state.SelectCompany))
      FilterDepartment = FilterDepartment.map((item) => item.Department)
      FilterDepartment = [...new Set(FilterDepartment)];
      this.setState({ DepartmenList: FilterDepartment, SelectBranch: selectedBranch});
    } else {
      this.setState({ 
        DepartmenList: [],
        CategoryList: [],
      });
    }
  }

  async getFilterCategory(selectedDepartment) {
    if (selectedDepartment !== '') {
      let FilterCategory = '';
      await this.setState({ CategoryList: []});
      const getCategoryList = await getCategory(
        this.props.username, 
        this.props.token,
        {
          department: selectedDepartment,
          Category: '', 
          sub1: '', 
          sub2: '', 
          sub3: '', 
          sub4: '',
          sub5: '',
        },
      );
      FilterCategory = getCategoryList.map((item) => item.Category)
      FilterCategory = [...new Set(FilterCategory)];
      this.setState({ CategoryList: FilterCategory, SelectDepartment: selectedDepartment});
    } 
    else {
      this.setState({ 
        CategoryList: [],
      });
    }
  }

  async getFilterSub1(SelectedAns, type) {
    this.ClearSubData(type);
    const AnsArray = (type === 'SelectCategory') ? 'Sub1List' : (type === 'SelectedSub1') ? 'Sub2List' : (type === 'SelectedSub2') ? 'Sub3List' : (type === 'SelectedSub3') ? 'Sub4List' : (type === 'SelectedSub4') ? 'Sub5List' : (type === 'SelectedSub5') ? 'Sub6List' : ''
    if (SelectedAns !== '') {
      const AnsArray = (type === 'SelectCategory') ? 'Sub1List' : (type === 'SelectedSub1') ? 'Sub2List' : (type === 'SelectedSub2') ? 'Sub3List' : (type === 'SelectedSub3') ? 'Sub4List' : (type === 'SelectedSub4') ? 'Sub5List' :  (type === 'SelectedSub5') ? 'Sub6List' : ''
      let FilterSub1 = '';
      await this.setState({ [AnsArray]: []});
      const getCategoryList = await getCategory(
        this.props.username, 
        this.props.token,
        {
          department: this.state.SelectDepartment,
          Category: (type === 'SelectCategory') ? SelectedAns : this.state.SelectCategory, 
          sub1: (type === 'SelectedSub1') ? SelectedAns : this.state.SelectedSub1, 
          sub2: (type === 'SelectedSub2') ? SelectedAns : this.state.SelectedSub2, 
          sub3: (type === 'SelectedSub3') ? SelectedAns : this.state.SelectedSub3, 
          sub4: (type === 'SelectedSub4') ? SelectedAns : this.state.SelectedSub4,
          sub5: (type === 'SelectedSub5') ? SelectedAns : this.state.SelectedSub5,
        },
      );
      const Answer = (type === 'SelectCategory') ? 'Sub1' : (type === 'SelectedSub1') ? 'Sub2' : (type === 'SelectedSub2') ? 'Sub3' : (type === 'SelectedSub3') ? 'Sub4' : (type === 'SelectedSub4') ? 'Sub5' : (type === 'SelectedSub5') ? 'Sub6' :  ''
      FilterSub1 = getCategoryList.map((item) => item[Answer])
      FilterSub1 = [...new Set(FilterSub1)];
      this.setState({ [AnsArray]: FilterSub1, [type]: SelectedAns});
    } 
    else {
      this.setState({ 
        [AnsArray]: [],
      });
    }
  }

  ClearSubData(type) {
    if (type === 'SelectCategory') {
      this.setState({SelectedSub1: '', SelectedSub2: '', SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub1') {
      this.setState({SelectedSub2: '', SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub2') {
      this.setState({SelectedSub3: '', SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub3') {
      this.setState({SelectedSub4: '', SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub4') {
      this.setState({SelectedSub5: '', SelectedSub6: ''})
    }
    if (type === 'SelectedSub5') {
      this.setState({SelectedSub6: ''})
    }
  }

  FilterSelectedOption(e, type) {
    if (type === 'SelectCompany') {
      if (e.target.value === '') {
        this.setState({ SelectCompany: '', SelectFilterCompany: '' })
        // this.FilteredBranch(JSON.parse(this.props.CompanyData))
      } else {
        this.getFilterBranch(e.target.value);
        this.setState({ 
          [type]: e.target.value, 
          // DepartmenList: [],
          // CategoryList: [],
          SelectFilterCompany: e.target.value, 
        })
      }
    } else if(type === 'SelectBranch') {
      // this.getFilterDepartment(e.target.value);
      this.setState({ 
        [type]: e.target.value,
        // CategoryList: [],
        SelectFilterBranch: e.target.value,
      })
    } else if(type === 'SelectDepartment') {
      // this.getFilterCategory(e.target.value);
      this.setState({ [type]: e.target.value, SelectFilterDepartment: e.target.value })
    } else if(type === 'SelectCategory' || type === 'SelectedSub1' || type === 'SelectedSub2' || type === 'SelectedSub3' || type === 'SelectedSub4' || type === 'SelectedSub5') {
      this.getFilterSub1(e.target.value, type);
      this.setState({ [type]: e.target.value})
      if (type === 'SelectCategory') {
        this.setState({ ShowFilterSub: e.target.value })
      }
      if (type === 'SelectedSub1') {
        this.setState({ ShowFilterSubSub: e.target.value })
      }
    } else if(type === 'SelectLatestUpdate') {
      this.setState({ [type]: e.target.value, ShowFilterStatus: e.target.value })
    } else if(type === 'SelectName') {
      if (e.target.value === '') {
        this.setState({ [type]: this.props.username}, () => {
          this.getCategories();
        })
      } 
      else {
        this.setState({ [type]: e.target.value, ShowFilterName: e.target.value }, () => {
          this.getCategories();
        })
      }
    }
  }

  async UserCallApi(Action){
    this.CallHeader();
    if (Action === true) {
      this.setState({ RunTotalPage: [] })
    }
    const DataObj = {
      selectedUser: this.state.SelectName,
      selectedCompany: this.state.SelectCompany, 
      selectedBranch: this.state.SelectBranch, 
      selectedDepartment: this.state.SelectDepartment, 
      selectedCategory: this.state.SelectCategory,
      subCategory: this.state.SelectedSub1,
      subCategory2: this.state.SelectedSub2,
      subCategory3: this.state.SelectedSub3,
      subCategory4: this.state.SelectedSub4,
      subCategory5: this.state.SelectedSub5,
      subCategory6: this.state.SelectedSub6,
      claimStatus: this.state.SelectLatestUpdate,
      claimAmount: this.state.KeyinAmount,
      approvedAmount: this.state.ApprovedAmount,
      FirstApprove: this.state.FirstApprove,
      FirstApproveStatus: this.state.FirstApproveStatus,
      Accountant: this.state.Accountant,
    }
    const fromDate = this.state.fromDate
    const toDate = this.state.toDate
    // const CallClaimRecord = await getClaimRecord(this.props.username, this.state.filterUser, this.state.fromDate, this.state.toDate, this.props.token);
    const getData = await cashOutClaimListFNC(fromDate, toDate, DataObj, 'Claim', this.props.username, this.state.page, this.state.countperpage, this.props.token)
   
    if (getData.message === 'No record for these day') {
      //ignore
      this.setState({ claimData: [], AllClaimData: [], TotalAmount: {TotalAmount: 0, TotalApproveAmount: 0} })
    } else {
      this.setState({ claimData: getData.claimList, AllClaimData: getData.claimList, TotalAmount:  getData.totalAmtOfClaim, TotalPages: Number(getData.totalPage) },() => {
        // this.CalculateTotalAmount(this.state.claimData);
        this.RunTotalPage();
        if(this.state.claimData.length > 0) {
          this.setState({ OpenFilter: false })
        }
      })
      if (getData.claimList.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            getData.claimList[0].FullCount / this.state.countperpage,
          ),
        });
      } else {
        this.setState({
          countpagemax: 1,
          page: 1,
        });
      }
    }
  }

  // CalculateTotalAmount(claimData) {
  //   let Total = {
  //     Amount: 0,
  //     AmountApprove: 0,
  //   }
  //   claimData.forEach((items) => {
  //     Total.Amount += Number(items.Amount)
  //     Total.AmountApprove += Number(items.ApproveAmount)
  //   })
  //   this.setState({ TotalAmount: Total })
  // }

  SelectedUser(e) {
    this.setState({ filterUser: e.value, SelectedUser: e}, () => {
      this.getClaimData();
    })
  }

  async getClaimData(action) {
    this.setState({ FilterCompany: [], FilterBranch: [], FilterDepartment: [],
      page: 1,
      countpagemax: 1,
      countpagemin: 1,
    }, ()=> {
      this.UserCallApi(action);
    })
    this.setState({ 
      // SelectFilterCompany: 'All',
      // SelectFilterBranch: 'All',
      // SelectFilterDepartment: 'All',
      // ShowFilterName: 'All',
      // ShowFilterSub: 'All',
      // ShowFilterSubSub: 'All',
      // ShowFilterApprovalStatus: 'All',
      // ShowFilterApproveAmount: 'All',
      // ShowFilterAmount: 'All',
      // ShowFilterApprover: 'All',
      // ShowFilterStatus: 'All',
      // ShowFilterAccounting: 'All',
      // SelectName: 'All',
      // SelectCompany: '', 
      // SelectBranch: '', 
      // ShowFilterBranch: '',
      // SelectDepartment: '', 
      // SelectCategory: '',
      // SelectedSub1: '',
      // SelectedSub2: '',
      // SelectedSub3: '',
      // SelectedSub4: '',
      // SelectedSub5: '',
      // SelectedSub6: '',
      // SelectLatestUpdate: '',
      // KeyinAmount: '',
      // ApprovedAmount: '',
      // FirstApprove: '',
      // FirstApproveStatus: '',
      // Accountant: '',
    })
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  handleClose() {
    this.setState({
      ClaimType: '', UploadModal: false, fastActions: false, reClaimfastActions: false,Base64file: '', SelectedAll: false, 
      Company: '', Branch: '', Department: '', EditID: '', Category: '', sub1: '', HistoryData: [], AllSelectedIDs: [], 
      sub2: '', sub3: '', sub4: '', sub5: '', sub6: '', ClaimTitle: '', Remark: '', DisplayHistory: false,
      AmountClaim: '', SelectedActions: [], CompanyArray: [], BranchArray: [], 
      CategoriesArray: [], sub1Array: [], sub2Array: [], sub3Array: [], sub4Array: [],
      sub5Array: [], sub6Array: [], DepartmentArray: [], SelectedReClaim: [], SelectedStatus: '', ReturnMessage: '',
      NewFilterArray: [],
    })
    this.fileObj = [];
    this.fileArray = [];
    this.Base64 = [];
    this.newfileArray = [];
  }

  async SelectedOption(e, type) {
    if(type === 'Company') {
      let uniqueBranch = []
      // const ReturnArray = await getCompany(this.props.username, this.props.token, e,'');
      const FilterCompanyList = this.state.CompanyBranch.filter(item => item.Company === e);
      FilterCompanyList.forEach((items) => {
        uniqueBranch.push(items.Branch)
      })
      uniqueBranch = [...new Set(uniqueBranch)];
      if(this.state.Company !== e) { this.ClearOptions('Company'); }
      this.setState({ BranchArray: uniqueBranch })
    }
    if(type === 'Branch') {
      let uniqueDepartment = []
      const getDep = await getCompany(this.props.username, this.props.token, this.state.Company, e);
      getDep.forEach((items) => {
        uniqueDepartment.push(items.Department)
      })
      uniqueDepartment = [...new Set(uniqueDepartment)];
      if(this.state.Branch !== e) { this.ClearOptions('Branch'); }
      this.setState({ DepartmentArray: uniqueDepartment })
    }
    if(type === 'Department') {
      let PassbackObj = {}
      PassbackObj = { department: e, Category: '', sub1: '', sub2: '', sub3: '', sub4: '', sub5: '' }
      const getCate = await getCategory(this.props.username, this.props.token, PassbackObj);
      let CategoriesA = []
      getCate.forEach((items) => {
        CategoriesA.push(items.Category)
      })
      CategoriesA = [...new Set(CategoriesA)];
      if(this.state.Department !== e) { this.ClearOptions('Department'); }
      this.setState({ CategoriesArray: CategoriesA })
    }
    if (type === 'Category' || type === 'sub1' || type === 'sub2' || type === 'sub3' || type === 'sub4' || type === 'sub5') {
      this.ClearOptions(type);
      this.setState({ [type]: e }, async()=> {
        let PassbackObj = {}
        PassbackObj = {
          department: this.state.Department,
          Category: this.state.Category, sub1: this.state.sub1, sub2: this.state.sub2, sub3: this.state.sub3, sub4: this.state.sub4,
          sub5: this.state.sub5,
        }
        const Filtertype = (type === 'Category') ? 'Sub1' : (type === 'sub1') ? 'Sub2' : (type === 'sub2') ? 'Sub3' : (type === 'sub3') ? 'Sub4' : (type === 'sub4') ? 'Sub5' : 'Sub6'
        const getSub = await getCategory(this.props.username, this.props.token, PassbackObj);
        let CategoriesA = []
        getSub.forEach((items) => {
          CategoriesA.push(items[Filtertype])
        })
        CategoriesA = [...new Set(CategoriesA)];
        const FilterEmpty = CategoriesA.filter(item => (item !== '' && item !== null));
        if (type === 'Category') {
          // if(this.state.Category !== e) { this.ClearOptions('Category'); }
          this.setState({ sub1Array: FilterEmpty })
        }
        if(type === 'sub1') {
          this.setState({sub2Array: FilterEmpty })
        }
        if(type === 'sub2') {
          this.setState({ sub3Array: FilterEmpty})
        }
        if(type === 'sub3') {
          this.setState({ sub4Array: FilterEmpty })
        }
        if(type === 'sub4') {
          this.setState({ sub5Array: FilterEmpty})
        }
        if(type === 'sub5') {
          this.setState({ sub6Array: FilterEmpty })
        }
      })
    }
    this.setState({ [type]: e })
  }
  
  ClearOptions(CurrentSelect) {
    if (CurrentSelect === 'Company') {
      this.setState({ Branch: '', Department: '', Category: '', sub1: '', sub2: '', sub3: '', sub4: '', sub5: '', sub6: '',
        DepartmentArray: [], CategoriesArray: [], sub1Array: [], sub2Array: [], sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'Branch') {
      this.setState({ Department: '', CategoriesArray: [], sub1Array: [], sub2Array: [], sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [],
        Category: '', sub1: '', sub2: '', sub3: '', sub4: '', sub5: '', sub6: '' })
    }
    if (CurrentSelect === 'Department') {
      this.setState({ Category: '', sub1: '', sub2: '', sub3: '', sub4: '', sub5: '', sub6: '',
        sub1Array: [], sub2Array: [], sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'Category') {
      this.setState({ sub1: '', sub2: '', sub3: '', sub4: '', sub5: '', sub6: '', sub2Array: [], sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'sub1') {
      this.setState({ sub2: '', sub3: '', sub4: '', sub5: '', sub6: '', sub3Array: [], sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'sub2') {
      this.setState({ sub3: '', sub4: '', sub5: '', sub6: '', sub4Array: [], sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'sub3') {
      this.setState({ sub4: '', sub5: '', sub6: '', sub5Array: [], sub6Array: [] })
    }
    if (CurrentSelect === 'sub4') {
      this.setState({ sub5: '', sub6: '', sub6Array: [] })
    }
    if (CurrentSelect === 'sub5') {
      this.setState({ sub6: '' })
    }
  }

  OpenClaimModal(type, SelectedClaim) {
    this.setState({ UploadModal: (type !== 'Reclaim') ? true : false, ClaimType: type }, async()=> {
      this.DisplayHistoryData();
      let uniqueOrderID = []
      //   const GetAllCompany = await getCompany(this.props.username, this.props.token, '','');
      const GetAllCompany = JSON.parse(this.props.CompanyData)
      GetAllCompany.forEach((items) => {
        uniqueOrderID.push(items.Company)
      })
      let uniqueOrderIDs = []
      uniqueOrderID = [...new Set(uniqueOrderID)];
      if (uniqueOrderID.includes('总公司 (HQ)')) {
        uniqueOrderIDs = uniqueOrderID.filter((value) => value !== '总公司 (HQ)');
        uniqueOrderIDs.unshift('总公司 (HQ)')
      } else {
        uniqueOrderIDs = uniqueOrderID
      }
      this.setState({ CompanyArray: uniqueOrderIDs, GetAllCompany})

      if (SelectedClaim !== 'none') {
        if(SelectedClaim.ReceiptImgURL !== undefined) {
          SelectedClaim.ReceiptImgURL.forEach((items) => {
            // this.fileArray.push(items.ReceiptImg)
            this.fileArray.push({Img: items.ReceiptImg, Base64: ''})
          })
        }
        this.setState({ 
          Company: SelectedClaim.Company || '', Branch: SelectedClaim.Branch || '', Department: SelectedClaim.Department || '', Category: SelectedClaim.Category || '',
          ClaimTitle: SelectedClaim.Subject || '', sub1: SelectedClaim.Sub1 || '', sub2: SelectedClaim.Sub2 || '', sub3: SelectedClaim.Sub3 || '', 
          sub4: SelectedClaim.Sub4 || '', sub5: SelectedClaim.Sub5 || '', sub6: SelectedClaim.Sub6 || '', Remark: SelectedClaim.Comment || '', ReturnMessage: SelectedClaim.Reason,
          AmountClaim: SelectedClaim.Amount || '', SelectedUSername: SelectedClaim.UploadBy, EditID: SelectedClaim.ID, SelectedStatus: SelectedClaim.Status,
        }, ()=> {
          this.SelectedOption(this.state.Company, 'Company')
          this.SelectedOption(this.state.Branch, 'Branch')
          this.SelectedOption(this.state.Department, 'Department')
          this.SelectedOption(this.state.Category, 'Category')
          this.SelectedOption(this.state.sub1, 'sub1')
          this.SelectedOption(this.state.sub2, 'sub2')
          this.SelectedOption(this.state.sub3, 'sub3')
          this.SelectedOption(this.state.sub4, 'sub4')
          this.SelectedOption(this.state.sub5, 'sub5')
        })
      }
    })
  }

  uploadMultipleFiles(e) {
    const BeforeFileArray = this.newfileArray || []
    const BeforeFilterfileArray = this.fileArray || []
    this.newfileArray = []
    this.fileArray = []
    this.fileObj = []
    BeforeFileArray.forEach((items) => {
      this.newfileArray.push(items)
    })
    BeforeFilterfileArray.forEach((items) => {
      this.fileArray.push(items)
    })
    // this.Base64 = []

    if (e.target.files.length > 0) {
      this.fileObj.push(e.target.files)
      for (let i = 0; i < this.fileObj[0].length; i++) {
        getBase64(this.fileObj[0][i]).then(
          Base64data => this.Base64.push(Base64data)
        )
        if(this.state.ClaimType === 'Upload') {
          this.fileArray.push({Img: `${URL.createObjectURL(this.fileObj[0][i])}${(this.fileObj[0][i].type === 'text/plain') ? '.txt' : ''}`, Base64: '' })
          this.SetBase64Function('Upload');
        } else {
          this.newfileArray.push({Img: `${URL.createObjectURL(this.fileObj[0][i])}${(this.fileObj[0][i].type === 'text/plain') ? '.txt' : ''}`, Base64: ''})
          this.SetBase64Function('Edit');
        }
      }
      // this.setState({ file: this.fileArray })
    }
  }

  SetBase64Function(Type) {
    let TestingArray = []
    timer = setTimeout(() => {
      TestingArray = this.Base64
      if (Type === 'Upload') {
        for(var i=0; i<this.fileArray.length; i++) {
          this.fileArray[i] = { ...this.fileArray[i], Base64: TestingArray[i] }
        }
      } else {
        for(var a=0; a<this.newfileArray.length; a++) {
          this.newfileArray[a] = { ...this.newfileArray[a], Base64: TestingArray[a] }
        }
      }
    }, 100);
    if(Type === 'Upload') {
      this.setState({ file: this.fileArray })
    } else {
      this.setState({ file: this.newfileArray })
    }
  }

  DeleteImage(url, index, type, Array) {
    let newfileArray = this.newfileArray
    let fileArray = this.fileArray
    if(type === 'Upload') {
      const afterFilter = fileArray.filter(item => item.Img !== url.Img);
      this.fileArray = []
      this.Base64 = []
      afterFilter.forEach((items) => {
        this.fileArray.push(items)
        this.Base64.push(items.Base64)
      })
      this.setState({ file: this.fileArray })
    } else {
      if(Array === 'fileArray') {
        const afterFilter = fileArray.filter(item => item.Img !== url.Img);
        this.fileArray = []
        afterFilter.forEach((items) => {
          this.fileArray.push(items)
        })
        this.setState({ file: this.fileArray })
      } else {
        const afterFilter = newfileArray.filter(item => item.Img !== url.Img)
        this.newfileArray = []
        this.Base64 = []
        afterFilter.forEach((items) => {
          this.newfileArray.push(items)
          this.Base64.push(items.Base64)
        })
        this.setState({ file: this.newfileArray })
      }
    }
  }

  async PassClaim() {
    const newfileArray = this.newfileArray
    const fileArray = this.fileArray

    let NewReceipt = []
    let OldChangedReceipt = []

    if(fileArray.length > 0) {
      fileArray.forEach((items) => {
        if(this.state.ClaimType === 'Upload') {
          NewReceipt.push(items.Base64)
        } else {
          OldChangedReceipt.push(items.Img)
        }
      })
    }
    if(newfileArray.length > 0) {
      newfileArray.forEach((items) => {
        NewReceipt.push(items.Base64)
      })
    }

    let Base64Files = []
    if(NewReceipt !== '') {
      NewReceipt.forEach((items) => {
        if(items.includes('data:image')) {
          Base64Files.push({filetype: 'image', base64: items})
        } else if (items.includes('data:text')) {
          Base64Files.push({filetype: 'txt', base64: items})
        } else if (items.includes('data:application/vnd.openxmlformats')) {
          Base64Files.push({filetype: 'xlsx', base64: items})
        } else if (items.includes('data:application/vnd.ms-excel')) {
          Base64Files.push({filetype: 'xls', base64: items})
        } else {
          Base64Files.push({filetype: 'pdf', base64: items})
        }
      })
    }

    let FinalObj = {}
    FinalObj = {
      selectedDate: this.state.selectedDate,
      Username: this.state.SelectedUSername,
      Company: this.state.Company,
      Branch: this.state.Branch,
      Department: this.state.Department,
      Category: this.state.Category,
      Sub1: this.state.sub1,
      Sub2: this.state.sub2,
      Sub3: this.state.sub3,
      Sub4: this.state.sub4,
      Sub5: this.state.sub5,
      Sub6: this.state.sub6,
      Title: this.state.ClaimTitle,
      Remark: this.state.Remark,
      Amount: this.state.AmountClaim,
      AffectedImageArray: (OldChangedReceipt.length > 0) ? OldChangedReceipt : [],
      Document: (Base64Files.length > 0) ? Base64Files : [],
    }
    if (this.state.Company === '') {
      this.openNotification(`${language[this.props.currentLanguage].CompanyErrorMEssage}`,'error')
    } else if (this.state.Branch === '') {
      this.openNotification(`${language[this.props.currentLanguage].BranchErrorMessage}`,'error')
    } else if (this.state.Department === '') {
      this.openNotification(`${language[this.props.currentLanguage].DepartmentErrorMessage}`,'error')
    } else if (this.state.Category === '') {
      this.openNotification(`${language[this.props.currentLanguage].ErrorSelectSubject}`,'error')
    } else if (this.state.AmountClaim === '') {
      this.openNotification(`${language[this.props.currentLanguage].AmountErrorMessage}`,'error')
    } else {
      if (this.state.ClaimType !== 'Upload') {
        this.setState({ uploadloading: true })
        FinalObj.ID = this.state.EditID
        if (this.state.SelectedStatus === 'Pending' || this.state.SelectedStatus === 'Return') {
          const editClaim = await EditClaim(FinalObj, this.state.SelectedStatus, this.props.username, this.props.token)
          if(editClaim.message === 'Please upload pdf file with maximum 5 page or below') {
            this.setState({ uploadloading: false })
            this.openNotification(`${language[this.props.currentLanguage].PdfToomanyPage}`,'error')
          }
          if (editClaim.message === 'Update successful') {
            this.setState({ uploadloading: false })
            this.openNotification(`${language[this.props.currentLanguage].SuccessEdit}`,'success')
            this.handleClose();
            this.UserCallApi(true);
          }
          if(editClaim.message === 'Redo successful') {
            this.setState({ uploadloading: false })
            this.openNotification(`${language[this.props.currentLanguage].SuccessruleOver}`,'success')
            this.handleClose();
            this.UserCallApi(true);
          }
        } else {
          this.openNotification(`${language[this.props.currentLanguage].NotAllowEditError}`,'error')
          this.setState({ uploadloading: false })
        }
      } else {
        this.setState({ uploadloading: true })
        const PostClaims = await PostClaim(this.props.username, this.props.token, FinalObj)
        if(PostClaims.message === 'Please upload pdf file with maximum 5 page or below') {
          this.setState({ uploadloading: false })
          this.openNotification(`${language[this.props.currentLanguage].PdfToomanyPage}`,'error')
        }
        if (PostClaims.message === 'Upload Success') {
          this.setState({ uploadloading: false })
          this.openNotification(`${language[this.props.currentLanguage].SuccessUploadClainm}`,'success')
          this.handleClose();
          this.UserCallApi(true);
        }
      }
    }
  }

  async SingleReClaim (Type) {
    const newfileArray = this.newfileArray

    let NewReceipt = []

    if(newfileArray.length > 0) {
      newfileArray.forEach((items) => {
        NewReceipt.push(items.Base64)
      })
    }

    let Base64Files = []
    if(NewReceipt !== '') {
      NewReceipt.forEach((items) => {
        if(items.includes('data:image')) {
          Base64Files.push({filetype: 'image', base64: items})
        } else if (items.includes('data:text')) {
          Base64Files.push({filetype: 'txt', base64: items})
        } else if (items.includes('data:application/vnd.openxmlformats')) {
          Base64Files.push({filetype: 'xlsx', base64: items})
        } else if (items.includes('data:application/vnd.ms-excel')) {
          Base64Files.push({filetype: 'xls', base64: items})
        } else {
          Base64Files.push({filetype: 'pdf', base64: items})
        }
      })
    }

    let FinalObj = {}
    FinalObj = {
      selectedDate: this.state.selectedDate,
      Username: this.state.SelectedUSername,
      Company: this.state.Company,
      Branch: this.state.Branch,
      Department: this.state.Department,
      Category: this.state.Category,
      Sub1: this.state.sub1,
      Sub2: this.state.sub2,
      Sub3: this.state.sub3,
      Sub4: this.state.sub4,
      Sub5: this.state.sub5,
      Sub6: this.state.sub6,
      Title: this.state.ClaimTitle,
      Remark: this.state.Remark,
      Amount: this.state.AmountClaim,
      Document: (Base64Files.length > 0) ? Base64Files : [],
    }
    if (this.state.Company === '') {
      this.openNotification(`${language[this.props.currentLanguage].CompanyErrorMEssage}`,'error')
    } else if (this.state.Branch === '') {
      this.openNotification(`${language[this.props.currentLanguage].BranchErrorMessage}`,'error')
    } else if (this.state.Department === '') {
      this.openNotification(`${language[this.props.currentLanguage].DepartmentErrorMessage}`,'error')
    } else if (this.state.Category === '') {
      this.openNotification('错误: 请选择项目','error')
    } else if (this.state.AmountClaim === '') {
      this.openNotification(`${language[this.props.currentLanguage].AmountErrorMessage}`,'error')
    } else {
      this.setState({ uploadloading: true })
      const PostClaims = await reClaim(this.props.username, this.props.token, FinalObj)
      if(PostClaims.message === 'Please upload pdf file with maximum 5 page or below') {
        this.setState({ uploadloading: false })
        this.openNotification(`${language[this.props.currentLanguage].PdfToomanyPage}`,'error')
      }
      if (PostClaims.message === 'successful reclaim') {
        this.setState({ uploadloading: false })
        this.openNotification(`${language[this.props.currentLanguage].SuccessReclaim}`,'success')
        // this.handleClose();
        this.UserCallApi(true);
        if (Type === 'Multi') {
          this.CloseReclaimClaim(this.state.SelectedReclaimData[0])
        } else {
          this.handleClose();
        }
      }
    }
  }
  // WatchReceipt(ClaimReceipts) {
  //   this.setState({ OpenViewReceiptModal: true, ClaimViewReceipt: ClaimReceipts })
  // }

  // CloseViewReceipt() {
  //   this.setState({ OpenViewReceiptModal: false, ClaimViewReceipt: '' })
  // }

  async DeleteClaims(SelectedClaim) {
    if(SelectedClaim.Status !== 'Pending') {
      this.openNotification(`${language[this.props.currentLanguage].ClaimNotAllowDelete}`,'error')
    } else {
      const r = (window.confirm(`${language[this.props.currentLanguage].DeleteConfirmation}`));
      if (r === true) {
        const deleteclaim = await DeleteClaim([SelectedClaim.ID], this.props.username, this.props.token);
        if (deleteclaim.message === 'Accountant don\'t have authorize to delete claim') {
          this.openNotification(`${language[this.props.currentLanguage].Fails}`,'error')
        } else {
          if (deleteclaim.message === 'Successful deleted') {
            this.openNotification(`${language[this.props.currentLanguage].SuccessDeleted}`,'success')
            this.handleClose();
            this.UserCallApi(true);
          }
        }
      } else {
        return null;
      }
    }
  }

  FilterTableView(e, Selecttype) {
    if(Selecttype === 'ApproverOption') {
      this.setState({ SelectedFilterStatus: e, SelectLatestUpdate: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'ShowFilterName') {
      this.setState({ ShowFilterName: e, SelectName: e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Department') {
      this.setState({ SelectFilterDepartment: [e], SelectDepartment: (e === 'All') ? '' : [e] }, () => { this.UserCallApi(true); this.getCategories();})
    }
    if(Selecttype === 'Company') {
      this.setState({ SelectFilterCompany: e, SelectCompany: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Branch') {
      this.setState({ ShowFilterBranch: e, SelectBranch: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Category') {
      this.setState({ ShowFilterSub: e, SelectCategory: (e === 'All') ? '' : e  }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Sub1') {
      this.setState({ ShowFilterSubSub: e, SelectedSub1: (e === 'All') ? '' : e  }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Amount') {
      this.setState({ ShowFilterAmount: e, KeyinAmount: (e === 'All') ? '' : e  }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'FirstApprove') {
      this.setState({ ShowFilterApprover: e, FirstApprove: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'FirstApproveStatus') {
      this.setState({ ShowFilterApprovalStatus: e, FirstApproveStatus: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'ApproveAmount') {
      this.setState({ ShowFilterApproveAmount: e, ApprovedAmount: (e === 'All') ? '' : e }, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'Accountant') {
      this.setState({ ShowFilterAccounting: e, Accountant: (e === 'All') ? '' : e}, () => { this.UserCallApi(true); })
    }
    if(Selecttype === 'SelectedSub1') {
      this.setState({ ShowFilterSubSub: e, SelectedSub1: (e === 'All') ? '' : e}, () => { this.UserCallApi(true); })
    }
    this.setState({ page: 1 })
    // if(SelectedFilterStatus !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Status === SelectedFilterStatus);
    // }
    // if(ShowFilterName !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Name === ShowFilterName);
    // }
    // if(ShowFilterDepart !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Department === ShowFilterDepart);
    // }
    // if(ShowFilterCompany !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Company === ShowFilterCompany);
    // }
    // if(ShowFilterSub !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Category === ShowFilterSub);
    // }
    // if(ShowFilterSubSub !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Sub1 === ShowFilterSubSub);
    // }
    // if(ShowFilterAmount !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Amount === ShowFilterAmount);
    // }
    // if(ShowFilterApprover !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.FirstApprove === ShowFilterApprover);
    // }
    // if(ShowFilterApprovalStatus !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.FirstApproveStatus === ShowFilterApprovalStatus);
    // }
    // if(ShowFilterApproveAmount !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.ApproveAmount === ShowFilterApproveAmount);
    // }
    // if(ShowFilterAccounting !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Accountant === ShowFilterAccounting);
    // }
    // if(ShowFilterBranch !== 'All') {
    //   AllClaimData = AllClaimData.filter((value) => value.Branch === ShowFilterBranch);
    // }
    // this.setState({ claimData: AllClaimData })
  }

  fastActions(type) {
    if (type === 'ReClaim') {
      this.setState({ 
        reClaimfastActions: !this.state.reClaimfastActions, 
        SelectedReClaim: [],
        SelectedActions: [],
        fasttype: 'ReClaim',
        fastActions: false })
    }
    else {
      this.setState({ 
        fastActions: !this.state.fastActions, 
        SelectedReClaim: [], 
        SelectedActions: [], 
        fasttype: 'Delete', 
        reClaimfastActions: false})
    }
  }
  SelectedActions(users) {
    let SelectedActions = JSON.parse(JSON.stringify(this.state.SelectedActions))
    if(SelectedActions.includes(users)) {
      SelectedActions = SelectedActions.filter(function (el) {
        return el !== users
      });
    } else {
      SelectedActions.push(users)
    }
    this.setState({ SelectedActions })
  }

  SelectAll(e) {
    const claimData = this.state.claimData
    let ArrayID = []
    let SelectedReClaimDetails = []
    if (e.target.checked) {
      claimData.forEach((items) => {
        SelectedReClaimDetails.push(items)
        ArrayID.push(items.ID)
      })
      this.setState({ SelectedAll: true })
    } else {
      SelectedReClaimDetails = []
      ArrayID = []
      this.setState({ SelectedAll: false })
    }
    this.setState({ SelectedActions: ArrayID, SelectedReClaimDetails })
    this.setState({ AllSelectedIDs: ArrayID, SelectedReClaimDetails })
  }

  SelectedReAllID(users) {
    let SelectedReClaimDetails = JSON.parse(JSON.stringify(this.state.SelectedReClaimDetails))
    const claimData = this.state.claimData
    const ArrayClaimDetails = claimData.filter(function (el) {
      return el.ID === users
    });
    SelectedReClaimDetails.push(ArrayClaimDetails[0])
    let AllSelectedIDs = JSON.parse(JSON.stringify(this.state.AllSelectedIDs))
    if(AllSelectedIDs.includes(users)) {
      AllSelectedIDs = AllSelectedIDs.filter(function (el) {
        return el !== users
      });
    } else {
      AllSelectedIDs.push(users)
    }
    this.setState({ AllSelectedIDs, SelectedReClaimDetails })
  }

  SelectedReClaim(users) {
    let SelectedReClaim = JSON.parse(JSON.stringify(this.state.SelectedReClaim))
    if(SelectedReClaim.includes(users)) {
      SelectedReClaim = SelectedReClaim.filter(function (el) {
        return el !== users
      });
    } else {
      SelectedReClaim.push(users)
    }
    this.setState({ SelectedReClaim })
  }

  async DeleteAccount(SelectedActions) {
    const r = (window.confirm(`${language[this.props.currentLanguage].DeleteConfirmation}`));
    if (r === true) {
      const deleteclaim = await DeleteClaim(SelectedActions, this.props.username, this.props.token);
      if (deleteclaim.message === 'Accountant don\'t have authorize to delete claim') {
        this.openNotification(`${language[this.props.currentLanguage].Fails}`,'error')
      } else {
        if (deleteclaim.message === 'Successful deleted') {
          this.openNotification(`${language[this.props.currentLanguage].SuccessDeleted}`,'success')
          this.handleClose();
          this.UserCallApi(true);
        }
      }
    } else {
      return null;
    }
  }
  async MultipleReClaim(SelectedReClaim) {
    const SelectedReClaimDetails = this.state.SelectedReClaimDetails
    // this.SetReClaimValue(SelectedReClaimDetails[0])
    this.setState({ SelectedReclaimData: SelectedReClaimDetails }, () => {
      this.OpenClaimModal('Reclaim', this.state.SelectedReclaimData[0])
    })
    // const r = (window.confirm(`${language[this.props.currentLanguage].ConfirmReClaimMessage}`));
    // if (r === true) {
    //   const reClaimResult = await reClaim(this.props.username, this.props.token, SelectedReClaim);
    //   if (reClaimResult.error) {
    //     this.openNotification(`${language[this.props.currentLanguage].Fails}`,'error')
    //   } else {
    //     if (reClaimResult.message === 'successful reclaim') {
    //       this.openNotification(`${language[this.props.currentLanguage].SuccessReclaim}`,'success')
    //       this.handleClose();
    //       this.UserCallApi(true);
    //     }
    //   }
    // } else {
    //   return null;
    // }
  }

  DisplayPDFFile(SelectedFile, type) {
    let test = ''
    if (type === 'Upload' && SelectedFile.includes('.txt')) {
      test = SelectedFile.substring(0, SelectedFile.length - 4)
    } else {
      test = SelectedFile
    }
    // URL of the text file
    const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
    if(isIOS) {
      var windowReference = window.open();
      fetch(test)
        .then(response => response.text())
        .then(text => {
          if (SelectedFile.includes('txt')) {
            const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            windowReference.location = url;
          } else {
            windowReference.location = test;
          }
        });
      // document.getElementById("openWindowButton").addEventListener("click", function() {
      //   var windowReference = window.open();
      //   fetch(test)
      //     .then(response => response.text())
      //     .then(text => {
      //       if (SelectedFile.includes('txt')) {
      //         const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
      //         const url = URL.createObjectURL(blob);
      //         windowReference.location = url;
      //       } else {
      //         windowReference.location = test;
      //       }
      //     });
      // });
    } else {
      // fetch(test)
      //   .then(response => response.text())
      //   .then(text => {
      //     if (SelectedFile.includes('txt')) {
      //       const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
      //       const url = URL.createObjectURL(blob);
      //       window.open(url, '_blank', 'noopener,noreferrer');
      //     } else {
      //       window.open(test, '_blank', 'noopener,noreferrer');
      //     }
      //   });
      fetch(test)
        .then(response => response.text())
        .then(text => {
          if (SelectedFile.includes('txt')) {
            const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank', 'noopener,noreferrer');
          } else {
            window.open(test, '_blank', 'noopener,noreferrer');
          }
        });
    }
  };

  DownloadImages(SelectedFile) {
    const link = SelectedFile.replace('https://ace-bt.s3.ap-southeast-1.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net').replace('https://ace-bt.s3.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net')
    fetch(link)
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = link.replace(/^.*[\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }

  // async RedoActions(SelectedClaim) {
  //   const r = (window.confirm(`${language[this.props.currentLanguage].ConfirmOverrule}`));
  //   if (r === true) {
  //     const RedoClaim = await redoClaimFNC(SelectedClaim, this.props.username, this.props.token)
  //     if(RedoClaim.error === 'database error') {
  //       this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`,'error')
  //     }
  //     if(RedoClaim.message === 'Redo successful') {
  //       this.openNotification(`${language[this.props.currentLanguage].SuccessruleOver}`,'success')
  //       this.handleClose();
  //       this.UserCallApi();
  //     }
  //   } else {
  //     return null;
  //   }
  // }

  async DisplayHistoryData() {
    this.setState({ DisplayHistory: !this.state.DisplayHistory }, async() => {
      // document.getElementById('scripted').focus();
      const EditID = this.state.EditID
      const callHistoryData = await getClaimHistoryRecordFNC(EditID, this.props.username, this.props.token)
      if(callHistoryData.error === 'database error') {
        this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`,'error')
        this.handleClose();
      } else {
        this.setState({ HistoryData: callHistoryData })
      }
    })
  }

  ClearFilter() {
    this.setState({ 
      // SelectFilterCompany: 'All',
      // SelectFilterBranch: 'All',
      // SelectFilterDepartment: 'All',
      // ShowFilterName: 'All',
      // ShowFilterSub: 'All',
      // ShowFilterSubSub: 'All',
      // ShowFilterApprovalStatus: 'All',
      // ShowFilterApproveAmount: 'All',
      // ShowFilterAmount: 'All',
      // ShowFilterApprover: 'All',
      // ShowFilterStatus: 'All',
      // ShowFilterAccounting: 'All',
      SelectName: 'All',
      SelectCompany: '',
      SelectBranch: [],
      SelectDepartment: [],
      SelectedSub1: '',
      SelectedSub2: '',
      SelectedSub3: '',
      SelectedSub4: '',
      SelectedSub5: '',
      SelectedSub6: '',
      SelectCategory: '',
      SelectLatestUpdate: '',
      KeyinAmount: '',
      ApprovedAmount: '',
      ShowFilterBranch: '',
      FirstApprove: '',
      FirstApproveStatus: '',
      Accountant: '',
    })
  }

  SetStatePage(Type) {
    if (Type === 'Next') {
      this.setState({ page: this.state.page + 1 },() => {
        this.changePage();
      })
    } else if (Type === 'previous') {
      this.setState({ page: this.state.page - 1 },() => { this.changePage(this.state.page);})
    } else {
      this.setState({ page: Type },() => { this.changePage(this.state.page);})
    }
  }

  RunPageViews(Pages) {
    this.setState({ page: Pages },() => { this.UserCallApi(true) })
  }

  async handleXLSX(){
    // const getExportData = await exportClaimListFNC();
    const DataObj = {
      selectedUser: this.state.SelectName,
      selectedCompany: this.state.SelectCompany, 
      selectedBranch: this.state.SelectBranch, 
      selectedDepartment: this.state.SelectDepartment, 
      selectedCategory: this.state.SelectCategory,
      subCategory: this.state.SelectedSub1,
      subCategory2: this.state.SelectedSub2,
      subCategory3: this.state.SelectedSub3,
      subCategory4: this.state.SelectedSub4,
      subCategory5: this.state.SelectedSub5,
      subCategory6: this.state.SelectedSub6,
      claimStatus: this.state.SelectLatestUpdate,
      claimAmount: this.state.KeyinAmount,
      approvedAmount: this.state.ApprovedAmount,
      FirstApprove: this.state.FirstApprove,
      FirstApproveStatus: this.state.FirstApproveStatus,
      Accountant: this.state.Accountant,
    }
    const getExportData = await exportClaimListFNC(this.state.fromDate, this.state.toDate, DataObj, 'Claim', this.props.username, this.props.token);
    await exportReportExcel( getExportData.claimList, this.state.fromDate, this.state.toDate, this.props.currentLanguage, 'Claim')
  }

  handleChangePackageNumber = OptionsPackageNumber => {
    let DepartArray = []
    OptionsPackageNumber.forEach((items) => {
      DepartArray.push(items.value)
    })
    this.setState({ SelectedDeparts: OptionsPackageNumber, SelectDepartment: DepartArray })
  }

  handleChangePackageBranch = OptionsPackageNumber => {
    let BranchArray = []
    OptionsPackageNumber.forEach((items) => {
      BranchArray.push(items.value)
    })
    this.setState({ SelectedBranchs: OptionsPackageNumber, SelectBranch: BranchArray }, () => {this.getCategories()})
  }

  CloseReclaimClaim(SelectedID) {
    const SelectedReClaimDetails = JSON.parse(JSON.stringify(this.state.SelectedReClaimDetails))
    let SelectedReclaimData = []
    this.setState({ SelectedReclaimData: [] }, () => {
      SelectedReclaimData = SelectedReClaimDetails.filter(function (el) {
        return el.ID !== SelectedID.ID
      });
      this.setState({ SelectedReclaimData, SelectedReClaimDetails: SelectedReclaimData}, () => {
        if(this.state.SelectedReClaimDetails.length > 0) {
          this.OpenClaimModal('Reclaim', this.state.SelectedReClaimDetails[0])
        } else {
          this.setState({ AllSelectedIDs: [], SelectedAll: false })
          this.handleClose();
        }
      })
    })
  }

  render() {
    return (
      <div>
        <Header/>
        <Container fluid style={{ fontSize: '12px'}}>
          <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <div className='ClaimPageContainer'>
            {this.state.SelectedReclaimData.length > 0 &&
            <Modal centered show={this.state.SelectedReclaimData.length > 0} onHide={() => this.CloseReclaimClaim(this.state.SelectedReclaimData[0])} size="lg" style={{ fontSize: '12px'}}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {`ReClaim Claim Total`}
                </Modal.Title>
                <Alert className="ModalAlertDesign" style={{ zIndex: 99, position: 'fixed', top: 80 }} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                </Alert>
              </Modal.Header>
              <Modal.Body>
                {this.state.uploadloading === true ?
                  <>
                    <div className="dcc ClaimInput">
                      <img src={loading} alt="Logo" className="dcc loadingimages"/>
                    </div>
                  </>
                  :
                  <>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Date}</div>
                      <div style={{ marginRight: 10, width: 140 }}>{this.state.selectedDate}</div>
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Status}:</div>
                      <div style={{ marginRight: 10, width: 140}}>{this.state.SelectedStatus}</div>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}</div>
                      <div style={{ width: '60%' }}>{this.state.SelectedUSername}</div>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Company}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.Company} onChange={(e) => this.SelectedOption(e.target.value, 'Company')}>
                        <option value=''>-</option>
                        {this.state.CompanyArray.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Branch}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.Branch} onChange={(e) => this.SelectedOption(e.target.value, 'Branch')}>
                        <option value=''>-</option>
                        {this.state.BranchArray.map((item, index) =>
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Department}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.Department} onChange={(e) => this.SelectedOption(e.target.value, 'Department')}>
                        <option value=''>-</option>
                        {this.state.DepartmentArray.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Subject}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.Category} onChange={(e) => this.SelectedOption(e.target.value, 'Category')}>
                        <option value=''>-</option>
                        {this.state.CategoriesArray.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}1</div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.sub1} onChange={(e) => this.SelectedOption(e.target.value, 'sub1')}>
                        <option value=''>-</option>
                        {this.state.sub1Array.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}2</div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.sub2} onChange={(e) => this.SelectedOption(e.target.value, 'sub2')}>
                        <option value=''>-</option>
                        {this.state.sub2Array.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className='ClaimInput'>
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Title}</div>
                      <Form.Control style={{ width: '60%', fontSize: '12px'}} value={this.state.ClaimTitle} onChange={(e) => this.setState({ ClaimTitle: e.target.value })}/>
                    </div>
                    <div className='ClaimInput'>
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Remark}</div>
                      <textarea rows="5" style={{ width: '60%', borderColor: 'lightgray', fontSize: '12px', minHeight: 95}} value={this.state.Remark} onChange={(e) => this.setState({ Remark: e.target.value })}></textarea>
                    </div>
                    <div className='ClaimInput'>
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Amount}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Control style={{ width: '60%', fontSize: '12px'}} value={this.state.AmountClaim} type="Number" onChange={(e) => this.setState({ AmountClaim: e.target.value })}/>
                    </div>
                    {this.state.SelectedStatus === 'Return' && 
                <div className='ClaimInput'>
                  <div className='UploadClaimTitle'>{language[this.props.currentLanguage].ReturnReason}</div>
                  <textarea style={{ width: '60%', borderColor: 'lightgray', fontSize: '12px'}} value={this.state.ReturnMessage} disabled={true}></textarea>
                </div>
                    }
                    <div style={{ marginTop: 5, marginBottom: 10}}>
                      <form>
                        <div className="form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10, marginTop: 10 }}>
                          {this.state.ClaimType === 'Upload' ?
                            <>
                              {(this.fileArray || []).map((url, ind) => (
                                <div key={ind} style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                                  <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                                    <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '80%' }}>{url.Img}</div>
                                    <div className='dcc' onClick={() => this.DisplayPDFFile(url.Img, 'Upload')} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                                    <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'Upload', 'fileArray')} style={{ cursor: 'pointer', padding: 3, color: 'red', width: '10%'  }}>{language[this.props.currentLanguage].Delete}</div>
                                  </div>
                                </div>
                              ))}
                            </> : 
                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                              {(this.fileArray || []).map((url, ind) => (
                                <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                                  <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '80%' }}>{url.Img}</div>
                                  <div className='dcc' onClick={() => this.DisplayPDFFile(url.Img)} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                                  <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'Edit', 'fileArray')}style={{ cursor: 'pointer', padding: 3, color: 'red', width: '10%'  }}>{language[this.props.currentLanguage].Delete}</div>
                                </div>
                              ))}
                              {(this.newfileArray || []).map((url, ind) => (
                                <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                                  <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '80%' }}>{url.Img}</div>
                                  <div className='dcc' onClick={() => this.DisplayPDFFile(url.Img)} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                                  <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'Edit', 'newfileArray')} style={{ cursor: 'pointer', padding: 3, color: 'red', width: '10%'  }}>{language[this.props.currentLanguage].Delete}</div>
                                </div>
                              ))}
                            </div>}
                        </div>
                        <div className="form-group">
                          <input disabled={(this.state.SelectedStatus === 'Return') ? true : false} type="file" accept=".jpg,.png,.jpeg,.pdf,.xls,.xlsx,.txt" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                        </div>
                        {this.state.SelectedStatus === 'CashOut' &&  
                      <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5, backgroundColor: '#027eab' }} onClick={() => this.SingleReClaim('Multi')}>
                        {language[this.props.currentLanguage].ReClaim}
                      </button>}
                      </form >
                    </div>
                  </>
                }
                <Button variant="danger" onClick={() => this.CloseReclaimClaim(this.state.SelectedReclaimData[0])}>{language[this.props.currentLanguage].Close}</Button>
              </Modal.Body>
            </Modal>
            }
            <Modal backdrop="static" centered show={this.state.UploadModal} size="lg" onHide={this.handleClose} style={{ fontSize: '12px'}}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {this.state.ClaimType === 'Upload' ? `Upload Claim` : `Edit Claim`}
                </Modal.Title>
                <Alert className="ModalAlertDesign" style={{ zIndex: 99, position: 'fixed', top: 80 }} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                </Alert>
              </Modal.Header>
              <Modal.Body>
                {this.state.uploadloading === true ?
                  <>
                    <div className="dcc ClaimInput">
                      <img src={loading} alt="Logo" className="dcc loadingimages"/>
                    </div>
                  </>
                  :
                  <>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Date}</div>
                      <div style={{ marginRight: 10, width: 140 }}>{this.state.selectedDate}</div>
                      {/* <Form.Control placeholder="从" value={this.state.selectedDate} readOnly={true} type="date" style={{ marginRight: 10, width: 140 }}/> */}
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Status}:</div>
                      <div style={{ marginRight: 10, width: 140, color: (this.state.SelectedStatus === 'Return') ? 'red' : 'black' }}>{this.state.SelectedStatus}</div>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Users}</div>
                      <div style={{ width: '60%' }}>{this.state.SelectedUSername}</div>
                      {/* <Form.Control style={{ width: '60%' }} value={this.state.SelectedUSername} disabled={true}/> */}
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Company}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.Company} disabled={(this.state.SelectedStatus === 'Return') ? true : false} onChange={(e) => this.SelectedOption(e.target.value, 'Company')}>
                        <option value=''>-</option>
                        {this.state.CompanyArray.map((item, index) =>
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Branch}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.Branch} disabled={(this.state.SelectedStatus === 'Return') ? true : false} onChange={(e) => this.SelectedOption(e.target.value, 'Branch')}>
                        <option value=''>-</option>
                        {this.state.BranchArray.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Department}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.Department} disabled={(this.state.SelectedStatus === 'Return') ? true : false} onChange={(e) => this.SelectedOption(e.target.value, 'Department')}>
                        <option value=''>-</option>
                        {this.state.DepartmentArray.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Subject}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.Category} disabled={(this.state.SelectedStatus === 'Return') ? true : false} onChange={(e) => this.SelectedOption(e.target.value, 'Category')}>
                        <option value=''>-</option>
                        {this.state.CategoriesArray.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}1</div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.sub1} disabled={(this.state.SelectedStatus === 'Return') ? true : false} onChange={(e) => this.SelectedOption(e.target.value, 'sub1')}>
                        <option value=''>-</option>
                        {this.state.sub1Array.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className="ClaimInput">
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].SubSubject}2</div>
                      <Form.Select style={{ width: '60%', fontSize: '12px' }} value={this.state.sub2} disabled={(this.state.SelectedStatus === 'Return') ? true : false} onChange={(e) => this.SelectedOption(e.target.value, 'sub2')}>
                        <option value=''>-</option>
                        {this.state.sub2Array.map((item, index) => 
                          <option key={index} value={item}>{item}</option>
                        )}
                      </Form.Select>
                    </div>
                    <div className='ClaimInput'>
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Title}</div>
                      <Form.Control style={{ width: '60%', fontSize: '12px'}} value={this.state.ClaimTitle} disabled={(this.state.SelectedStatus === 'Return') ? true : false} onChange={(e) => this.setState({ ClaimTitle: e.target.value })}/>
                    </div>
                    <div className='ClaimInput'>
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Remark}</div>
                      <textarea rows="5" style={{ width: '60%', borderColor: 'lightgray', fontSize: '12px', minHeight: 95}} disabled={(this.state.SelectedStatus === 'Return') ? true : false} value={this.state.Remark} onChange={(e) => this.setState({ Remark: e.target.value })}></textarea>
                    </div>
                    <div className='ClaimInput'>
                      <div className='UploadClaimTitle'>{language[this.props.currentLanguage].Amount}<div style={{ color: 'red' }}>*</div></div>
                      <Form.Control style={{ width: '60%', fontSize: '12px'}} value={this.state.AmountClaim} disabled={(this.state.SelectedStatus === 'Return') ? true : false} type="Number" onChange={(e) => this.setState({ AmountClaim: e.target.value })}/>
                    </div>
                    {this.state.SelectedStatus === 'Return' && 
                  <div className='ClaimInput'>
                    <div className='UploadClaimTitle'>{language[this.props.currentLanguage].ReturnReason}</div>
                    <textarea style={{ width: '60%', borderColor: 'lightgray', fontSize: '12px'}} value={this.state.ReturnMessage} disabled={true}></textarea>
                  </div>
                    }
                    <div style={{ marginTop: 5, marginBottom: 10}}>
                      <form>
                        <div className="form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10, marginTop: 10 }}>
                          {this.state.ClaimType === 'Upload' ?
                            <>
                              {(this.fileArray || []).map((url, ind) => (
                                <div key={ind} style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                                  <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                                    <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '80%' }}>{url.Img}</div>
                                    <div className='dcc' onClick={() => this.DisplayPDFFile(url.Img, 'Upload')} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                                    <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'Upload', 'fileArray')} style={{ cursor: 'pointer', padding: 3, color: 'red', width: '10%'  }}>{language[this.props.currentLanguage].Delete}</div>
                                  </div>
                                </div>
                              ))}
                            </> : 
                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%'}}>
                              {(this.fileArray || []).map((url, ind) => (
                                <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                                  <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '80%' }}>{url.Img}</div>
                                  <div className='dcc' onClick={() => this.DisplayPDFFile(url.Img)} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                                  <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'Edit', 'fileArray')}style={{ cursor: 'pointer', padding: 3, color: 'red', width: '10%'  }}>{language[this.props.currentLanguage].Delete}</div>
                                </div>
                              ))}
                              {(this.newfileArray || []).map((url, ind) => (
                                <div style={{ border: '1px solid black', display: 'flex', flexDirection: 'row', padding: 2, width: '100%'}}>
                                  <div style={{ borderRight: '1px solid black', padding: 3, wordBreak: 'break-word', width: '80%' }}>{url.Img}</div>
                                  <div className='dcc' onClick={() => this.DisplayPDFFile(url.Img)} style={{ cursor: 'pointer', padding: 3, color: 'blue', width: '10%'  }}>{'View'}</div>
                                  <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'Edit', 'newfileArray')} style={{ cursor: 'pointer', padding: 3, color: 'red', width: '10%'  }}>{language[this.props.currentLanguage].Delete}</div>
                                </div>
                              ))}
                            </div>}
                        </div>
                        <div className="form-group">
                          <input disabled={(this.state.SelectedStatus === 'Return') ? true : false} type="file" accept=".jpg,.png,.jpeg,.pdf,.xls,.xlsx,.txt" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                        </div>
                        <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.PassClaim()}>
                          {this.state.ClaimType === 'Upload' ? `${language[this.props.currentLanguage].UploadClaim}` : `${language[this.props.currentLanguage].UpdateClaim}`}
                        </button>
                        <button type="button" className="btn btn-danger btn-block" style={{ marginTop: 10, marginRight: 5 }} onClick={() => this.DeleteClaims({ ID: this.state.EditID, Status: this.state.SelectedStatus })}>
                          {`${language[this.props.currentLanguage].Delete}`}
                        </button>
                        {this.state.SelectedStatus === 'CashOut' &&  
                        <button type="button" className="btn btn-success btn-block" style={{ marginTop: 10, marginRight: 5, backgroundColor: '#027eab' }} onClick={() => this.SingleReClaim()}>
                          {language[this.props.currentLanguage].ReClaim}
                        </button>}
                      </form >
                    </div>
                  </>
                }
                <div style={{ width: '80%'}}>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>{language[this.props.currentLanguage].ActionsUser}</th>
                        <th>{language[this.props.currentLanguage].Date}</th>
                        <th>{language[this.props.currentLanguage].Remark}</th>
                        <th>{language[this.props.currentLanguage].Reason}</th>
                        <th>{language[this.props.currentLanguage].Amount}</th>
                        <th>{language[this.props.currentLanguage].Action}</th>
                      </tr>
                    </thead>
                    {this.state.HistoryData.length > 0 &&
                    <tbody>
                      {this.state.HistoryData.map((items, index) => {
                        return(
                          <tr key={index}>
                            <td>{items.ActionUser}</td>
                            <td>{moment(items.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td style={{ wordBreak: 'break-word' }}>{items.Comment}</td>
                            <td>{items.Reason}</td>
                            <td>{items.Amount}</td>
                            {/* <td>{items.Status}</td> */}
                            <td>{language[this.props.currentLanguage][items.Status]}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                    }
                    {this.state.HistoryData.length === 0 &&
                    <tbody>
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center', fontWeight: 'bold' }}>No History Data</td>
                      </tr>
                    </tbody>
                    }
                  </Table>
                </div>
                <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button>
              </Modal.Body>
            </Modal>
            <div className='ClaimPageDateContainer'>
              <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Claim}</h2>
              <Form.Control value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e.target.value })} type="date" style={{ marginRight: 10, width: 140, minWidth: 140 }}/>
              <div style={{ marginRight: 10 }}>-</div>
              <Form.Control value={this.state.toDate} onChange={(e) => this.setState({ toDate: e.target.value })} type="date" style={{ marginRight: 10, width: 140, minWidth: 140 }}/>
              <div className="dcc button ClaimSearch"
                style={{ backgroundColor: this.state.background,
                  fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, paddingLeft: 12, paddingRight: 12}} 
                onMouseEnter={() => {
                  this.setState({ background: '#00B79B' });
                }}
                onMouseLeave={() => {
                  this.setState({ background: '#0D6EFD' });
                }}
                variant="success" onClick={() => this.getClaimData(true)}>
                <span>
                  {language[this.props.currentLanguage].Search}
                </span>
              </div>
              <Button style={{ minWidth: 80, fontWeight: 'bold', marginRight: 10, marginLeft: 10 }} onClick={() => this.setState({SelectedUSername: this.props.username }, () => { this.OpenClaimModal('Upload', 'none') })} variant="success">{language[this.props.currentLanguage].UploadClaim}</Button>
            </div>
            <div className='dcc'>
              {((this.props.role === 'User') || 
            (this.state.filterUser === this.props.username)) 
            && this.state.claimData.length > 0 && 
            <>
              <Button style={{ 
                minWidth: 80, 
                fontWeight: 'bold', 
                backgroundColor: '#027eab',
                marginRight: 10, }}
              onClick={()=> this.MultipleReClaim(this.state.AllSelectedIDs)}
              variant="success"> {language[this.props.currentLanguage].ReClaim}</Button>
            </>}
              {this.state.claimData.length > 0 && this.props.role === 'CEO' && 
            <>
              <Button style={{ marginRight: 10, backgroundColor: 'red' }} onClick={()=> this.DeleteAccount(this.state.AllSelectedIDs, 'Multi')}>{language[this.props.currentLanguage].FastDelete}</Button>
            </>
              }
            </div>
          </div>
          {this.state.OpenFilter && 
        <>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5}}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto',  width: '25%' }}>{language[this.props.currentLanguage].Company}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.SelectFilterCompany} onChange={(e) => this.FilterSelectedOption(e, 'SelectCompany')}>
                    <option value=''></option>
                    {this.state.CompanyList.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Branch}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  {this.state.SelectedOptionBranch.length > 0 && 
                  <div style={{ width: 250, maxWidth: 250 }}>
                    <Select closeMenuOnSelect={false} isMulti options={this.state.SelectedOptionBranch} value={this.state.SelectedBranchs} onChange={this.handleChangePackageBranch}/>
                  </div>
                  }
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Subject}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.ShowFilterSub} onChange={(e) => this.FilterSelectedOption(e, 'SelectCategory')}>
                    <option value=''></option>
                    {this.state.FilterSub.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Amount}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Control  value={this.state.KeyinAmount} readOnly={false} style={{ width: 250, textAlign: 'center', backgroundColor: 'white'  }} onChange={(e) => this.setState({ KeyinAmount: e.target.value })}/>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginBottom: 10}}>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Name}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} onChange={(e) => this.FilterSelectedOption(e, 'SelectName')} disabled={true}>
                    <option value={this.props.username}>{this.props.username}</option>
                  </Form.Select>
                </div>
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].Department}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  {this.state.SelectedOptionDepart.length > 0 && 
                  <div style={{ width: 250, maxWidth: 250 }}>
                    <Select closeMenuOnSelect={false} isMulti options={this.state.SelectedOptionDepart} value={this.state.SelectedDeparts} onChange={this.handleChangePackageNumber}/>
                  </div>
                  }
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{language[this.props.currentLanguage].LatestStatus}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.ShowFilterStatus} onChange={(e) => this.FilterSelectedOption(e, 'SelectLatestUpdate')}>
                    <option value=''></option>
                    {this.state.LatestUpdateList.map((item, index) => 
                      <option key={index} value={item}>{item}</option>
                    )}
                  </Form.Select>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5}}>
                <div className='ReportSelectTitle'>{'Page'}</div>
                <div style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}>
                  <Form.Select style={{ width: 250}} value={this.state.countperpage} onChange={(e) => this.setState({ countperpage: e.target.value })}>
                    <option value='20'>20</option>
                    <option value='30'>30</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </Form.Select>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: '13%',  marginLeft: '13%', justifyContent: 'space-between'}}>
            <div className="dcc button"
              style={{ backgroundColor: this.state.background,
                fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, width: 200, marginBottom: 10, marginTop: 5 }}
              variant="success" onClick={() => this.getClaimData(true)}>
              <span>
                {language[this.props.currentLanguage].Search}
              </span>
            </div>
          </div>
        </>
          }
          {this.state.OpenFilter === false && 
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '5%', justifyContent: 'space-between'}}>
          <div className="dcc button"
            style={{ backgroundColor: this.state.background,
              fontWeight: 'bold', padding: 7 , cursor: 'pointer', borderRadius: 5, width: 200, marginBottom: 10 }}
            variant="success" onClick={() => this.setState({ OpenFilter: !this.state.OpenFilter }, () => {
              // this.ClearFilter();
            })}>
            <span>
              {'OpenFilter'}
            </span>
          </div>
        </div>}
          <div className='dcc'>
            <DisplayTable
              BorderHide={this.state.BorderHide}
              SetStateProps={this.SetStateProps}
              handleXLSX={this.handleXLSX}
              RunPageViews={this.RunPageViews}
              TotalPages={this.state.TotalPages}
              RunTotalPage={JSON.parse(JSON.stringify(this.state.RunTotalPage))}
              SetStatePage={this.SetStatePage}
              page={this.state.page}
              countpagemin={this.state.countpagemin}
              countpagemax={this.state.countpagemax}
              FilterTableView={this.FilterTableView} 
              FilterCompany={this.state.FilterCompany} 
              FilterBranch={this.state.FilterBranch} 
              FilterDepart={this.state.FilterDepartment}
              FilterStatus={this.state.FilterStatus}
              FilterName={this.state.FilterName}
              FilterAmount={this.state.FilterAmount}
              FilterApproveAmount={this.state.FilterApproveAmount}
              FilterApprove={this.state.FilterApprove}
              FilterApprovalStatus={this.state.FilterApprovalStatus}
              FilterAccounting={this.state.FilterAccounting}
              FilterSub={this.state.FilterSub}
              FilterSubSub={this.state.FilterSubSub}
              ShowFilterStatus={this.state.SelectedFilterStatus}
              ShowFilterCompany={this.state.SelectFilterCompany}
              ShowFilterBranch={this.state.ShowFilterBranch}
              ShowFilterDepartment={this.state.SelectFilterDepartment}
              ShowFilterName={this.state.ShowFilterName}
              ShowFilterSub={this.state.ShowFilterSub}
              ShowFilterSubSub={this.state.ShowFilterSubSub}
              ShowFilterApprovalStatus={this.state.ShowFilterApprovalStatus}
              ShowFilterApproveAmount={this.state.ShowFilterApproveAmount}
              ShowFilterAmount={this.state.ShowFilterAmount}
              ShowFilterApprover={this.state.ShowFilterApprover}
              ShowFilterAccounting={this.state.ShowFilterAccounting}
              claimData={this.state.claimData}
              TotalAmount={this.state.TotalAmount}
              role={this.props.role} 
              username={this.props.username} 
              OpenClaimModal={this.OpenClaimModal} 
              WatchReceipt={this.WatchReceipt}
              SelectAll={this.SelectAll}
              SelectedAll={this.state.SelectedAll}
              selectedIDs={this.state.SelectedActions}
              DeleteClaims={this.DeleteClaims} 
              fastActions={this.state.fastActions} 
              reClaimfastActions={this.state.reClaimfastActions} 
              SelectedActions={this.SelectedActions} 
              SelectedReClaim={this.SelectedReClaim}
              pagetype={this.state.pagetype} 
              SelectedReAllID={this.SelectedReAllID}
              AllSelectedIDs={this.state.AllSelectedIDs}
              fasttype={this.state.fasttype}/>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    CompanyData: state.CompanyData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Claim);
